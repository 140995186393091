<template>
  <div class="role">
    <div class="title">
      <span>系统设置 / </span> <span class="black">角色管理</span>
    </div>
    <el-card class="condition_style2">
      <el-form :inline="true" class="flex" :model="searchForm">
        <el-form-item v-if="poewrMenuIdList[0].isok==1">
          <el-button type="primary" size="small" @click="add('true')">新增</el-button>
        </el-form-item>
        <div v-if="poewrMenuIdList[1].isok==1">
          <el-form-item>
            <el-input v-model="searchForm.name" placeholder="请输入角色名称" suffix-icon="el-icon-search" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.flag" placeholder="请选择状态" clearable>
              <el-option v-for="(item, index) in list" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(searchForm.page-1) * searchForm.limit + 1}}</template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称" min-width="120" align="center"></el-table-column>
        <!-- <el-table-column prop="roleCode" label="权限字符" min-width="120" align="center"></el-table-column> -->
        <el-table-column prop="roleFlag" label="状态" min-width="100" align="center">
          <template slot-scope="scope">
            <span class="dot" :class="stateClass(scope.row.roleFlag)"></span> {{showState(scope.row.roleFlag)}}
          </template>
        </el-table-column>
        <el-table-column prop="roleUpdatetime" label="最后操作时间" min-width="180" align="center"></el-table-column>
        <el-table-column prop="operateName" label="操作人" min-width="120" align="center"></el-table-column>
        <el-table-column prop="roleMemo" label="备注" min-width="180" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.roleId!='1'">
              <el-button v-if="poewrMenuIdList[2].isok==1" type="text" size="small" @click="release(scope.row)">
                {{scope.row.roleFlag==1?'禁用':scope.row.roleFlag==0?'启用':''}} </el-button> <span class="ge"
                v-if="poewrMenuIdList[2].isok==1&&poewrMenuIdList[3].isok==1">|</span>
              <el-button v-if="poewrMenuIdList[3].isok==1" class="ge" type="text" size="small"
                @click="add(scope.row.roleId)"> 编辑</el-button>
              <!-- <span class="ge" v-if="poewrMenuIdList[3].isok==1&&poewrMenuIdList[4].isok==1">|</span> -->
              <!-- <el-button v-if="poewrMenuIdList[4].isok==1" type="text" size="small" @click="dataPower(scope.row)"> 数据权限</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="searchForm.page" :size="searchForm.limit" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>

    <!-- 数据权限 -->
    <modal :obj="{ title: '分配数据权限', dialog: editDialog,  dialogText: 'editDialog', form: editForm, ref: 'editForm', }"
      @submit="dataPowerSubmit">
      <el-form :model="editForm" label-width="130px" :rules="rules" ref='editForm'>
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="editForm.roleName" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="字符权限：" prop="roleCode">
          <el-input v-model="editForm.roleCode" disabled></el-input>
        </el-form-item> -->
        <el-form-item label="权限范围：" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择权限范围" class="w_100">
            <el-option v-for="(item, index) in powerList" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据权限：" prop="arr" v-if="editForm.type==2">
          <el-cascader class="w_100" v-model="editForm.arr" clearable :options="options" filterable
            placeholder="请选择数据权限" :props="{ value: 'buttonId', label: 'buttonName',multiple: true }"
            @change="handleChange"></el-cascader>
          <!-- <el-select v-model="editForm.arr" multiple placeholder="请选择数据权限" class="w_100" :filterable="true">
            <el-option v-for="(item,key) in powerList" :key="key" :label="item.label" :value="item.value"> </el-option>
          </el-select> -->
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../../components/pagination";
  import modal from "../../../components/modal";
  import {
    getRoleList,
    roleUpdown,
    OneMenu,
    newrolebuttonSave,
    dataPowerInfo,
    userListMenuId
  } from "../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        page: 1,
        limit: 10,
        total: 0,
        tableHeight: this.$util.getHeight(330),
        options: [], //菜单
        searchForm: {
          name: "",
          flag: '',
          limit: 10,
          page: 1,
        },
        tableData: [],
        list: [{
            value: null,
            label: "全部",
          }, {
            value: "0",
            label: "禁用",
          },
          {
            value: "1",
            label: "启用",
          },
        ],
        powerList: [{
            value: "1",
            label: "全部数据权限",
          },
          {
            value: "2",
            label: "自定义数据权限",
          },
        ],
        showBtn: false,
        editDialog: false,
        edit: "",
        editForm: {
          entities: [],
          roleId: '',
          type: '', //1全部数据权限2自定义
          arr: []
        },
        rules: {
          type: [{
            required: true,
            message: "请选择权限范围"
          }],
        },
      };
    },
    computed: {
      userInof() {
        return this.$store.state.userInof;
      },
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
      leftMenuId() {
        return this.$store.state.currentMenuId ? this.$store.state.currentMenuId : ''
      },
    },
    created() {
      if (this.$route.params.form) {
        this.searchForm = JSON.parse(this.$route.params.form)
      }
      this.getList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.tableHeight = this.$util.getHeight(330)
      })
    },
    methods: {
      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.searchForm.pageFlag = this.searchForm.pageFlag ? "0" : "1"
        this.$request(getRoleList, this.searchForm).then(
          (res) => {
            if (res.code === "200") {
              let data = res.data
              this.tableData = data.list
              this.total = Number(data.totalCount)
              this.searchForm.pageFlag = this.searchForm.pageFlag == 0 ? true : false
              this.showPagination = this.searchForm.pageFlag == 0 ? true : false
            }
          }
        );
      },
      //数据权限数据刷新
      getUserListMenuId() {
        this.$request(userListMenuId(this.leftMenuId)).then((res) => {
          if (res.code === "200") {
            let data = res.data
            this.$store.commit('poewrMenuIdListFn', [])
            this.$store.commit('poewrMenuIdListFn', data)
          }
        });
      },

      // 选择自定义e权限
      handleChange(e) {
        this.$forceUpdate()
        this.editForm.entities = []
        let arr = e
        if (arr) {
          arr.find((item) => {
            this.editForm.entities.push(item[1])
          })
        }

      },
      //   新增\编辑 
      add(val) {
        this.$router.push({
          name: "addRole",
          params: {
            id: val,
            form: JSON.stringify(this.searchForm),
          }
        })
      },
      // 启用禁用
      release(val) {
        this.$util.isSwal(val.roleFlag == 1 ? "确定禁用？" : "确定启用？", this.releaseSubmit, val)
      },
      releaseSubmit(val) {
        this.$request(roleUpdown(val.roleId, val.roleFlag == 0 ? '1' : '0')).then(
          (res) => {
            if (res.code === "200") {
              this.$util.message("suc", "操作成功");
              this.getList()
            }
          }
        )
      },
      //获取数据权限信息
      getPowerInfo(val) {
        this.$request(dataPowerInfo(val.roleId)).then((res) => {
          if (res.code === "200") {
            let data = res.data
            let enities = data.newMenuButtonEntities.map((item, index) => {
              return Array.from(index).concat(item.menuId).concat(item.buttonId)
            })
            this.editForm = data
            if (this.editForm.type == 2) {
              this.editForm.entities = []
              enities.find((item) => {
                this.editForm.entities.push(item[1])
              })
              this.editForm.arr = this.$util.superCopy(enities)
            }
          }
        })
      },
      // 数据权限-弹框
      dataPower(val) {
        this.getList()
        this.editForm = {}
        if (this.$refs.editForm) {
          this.$refs.editForm.resetFields();
        }
        this.getPowerInfo(val)
        this.edit = val;
        this.editDialog = true;
        this.$request(OneMenu).then((res) => {
          if (res.code === "200") {
            let data = res.data
            let dataPowerArr = []
            if (data) {
              data.map((item) => {
                if (item.newMenuAndButtonVos) {
                  item.children = item.newMenuAndButtonVos
                  item.children.map((val) => {
                    dataPowerArr.push(val)
                    if (val.entities) {
                      val.children = val.entities
                      if (val.menuId && val.menuName) {
                        val.buttonId = val.menuId
                        val.buttonName = val.menuName
                      }

                    }

                  })
                }

              })
            }
            this.options = dataPowerArr
          }
        })

      },
      //   数据权限-接口
      dataPowerSubmit() {
        delete this.editForm.arr
        delete this.editForm.newMenuButtonEntities
        this.editForm.roleId = this.edit.roleId
        this.$refs.editForm.validate((result) => {
          if (result) {
            this.$request(newrolebuttonSave, this.editForm).then(
              (res) => {
                if (res.code === "200") {
                  this.$util.message("suc", "操作成功");
                  this.editDialog = false;
                  this.getList()
                  this.getUserListMenuId()
                }
              }
            )
          }

        })
      },

      handleSizeChange(val) {
        this.searchForm.limit = val;
        this.getList()
      },
      handleCurrentChange(val) {
        this.searchForm.page = val;
        this.getList();
      },
      showState(val) {
        switch (val) {
          case "0":
            return "禁用";
          case "1":
            return "启用";
        }
      },
      stateClass(val) {
        switch (val) {
          case "0":
            return "red_dot";
          case "1":
            return "green_dot";
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .role {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }
  }
</style>